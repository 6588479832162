import baseConfig from '~/baseConfig'
const BASE_URL = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].apis[process.env.VUE_APP_API_ENV][0];
const check_watermark_func = {
    // 含义 - 用于：1-企业画像列表页，2-企业画像详情页，3-企业证书，4-企业资质证书，5-企业图片，6-展台图片
    urlArr: [
        "service/company/api/list/whole",
        "service/media/api/video_and_picture/list",
        "service/company/api/company_certificate/list",
        "service/media/api/company_picture/list",
        "service/company_vip/api/company/qualification",
        "daily_chat/api/picture_collection/list"
    ],
    check_watermark: (config) => {
        let check_watermark = false;
        for (let i = 0; i < check_watermark_func.urlArr.length; i++) {
            let urlStr = check_watermark_func.urlArr[i];
            if (config.url.indexOf(urlStr) != -1) {
                check_watermark = true;
                break;
            }
        }
        return check_watermark;
    },
    check_watermark_usage:(config)=>{
        let watermark_usage = 1;
        if(config.url){
            if(config.url.indexOf('service/company/api/list/whole') != -1){
                watermark_usage = 1;
            }else if(config.url.indexOf('service/media/api/video_and_picture/list') != -1){
                watermark_usage = 2;
            }else if(config.url.indexOf('service/media/api/company_picture/list') != -1){
                watermark_usage = 5;
            }else if(config.url.indexOf('service/company_vip/api/company/qualification') != -1){
                watermark_usage = 3;
            }else if(config.url.indexOf('service/company/api/company_certificate/list') != -1){
                watermark_usage = 4;
            }else if(config.url.indexOf('daily_chat/api/picture_collection/list') != -1){
                watermark_usage = 6;
            }
        }
        return watermark_usage;
    }
}
axios.interceptors.request.use((config) => {
    if (config.url.indexOf('http') == -1) {
        if ((config.data && config.data._PREFIX) || (config.params && config.params._PREFIX)) {
            let prefix;
            if (config.data) {
                prefix = config.data._PREFIX
                delete config.data._PREFIX;
            } else if (config.params) {
                prefix = config.params._PREFIX
                delete config.params._PREFIX;
            }
            let url = BASE_URL.replace('service', prefix)
            config.url = `${url}${config.url}`;
        } else {
            config.url = `${BASE_URL}${config.url}`
        }
    }
    for (let key in config.data) {
        if (config.data && !config.data._NOCLEAR) {
            if (config.data[key] === '' || config.data[key] === undefined || config.data[key] === null) {
                if (config.data._EMPTYITEM && config.data._EMPTYITEM.indexOf(key) != -1) {

                } else {
                    delete config.data[key]
                }
            }
        }
    }
    if (config.data && config.data._DELETEITEM) {
        config.data._DELETEITEM.forEach(item => {
            delete config.data[item]
        });
        delete config.data._DELETEITEM;
    }
    if (config.data && config.data._EMPTYITEM) delete config.data._EMPTYITEM;
    if (config.data && config.data._NOCLEAR) delete config.data._NOCLEAR;

    if(config.url && check_watermark_func.check_watermark(config)){
        if(config.data){
            config.data.website = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            config.data.usage = check_watermark_func.check_watermark_usage(config)
        }else{
            config.params.website = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
            config.params.usage = check_watermark_func.check_watermark_usage(config)
        }
    }
    config.data = Qs.stringify(config.data)
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
    return config;
}, function (error) {

    return Promise.reject(error);
});

axios.interceptors.response.use((data) => {
    return data;
})