<template>
  <div class="ailaWrtLayouts">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.ailaWrtLayouts{
  height: 100%;
}
</style>