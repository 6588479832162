import Vue from 'vue'
Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [{
        path: '/',                                              //首页
        name: 'index',
        component: resolve => require(['~wrt/views/biunique/index'], resolve),
    }, {
        path: '/negotiation',
        name: 'negotiation',
        component: resolve => require(['~wrt/views/biunique/index'], resolve)
    },
    {
        path: '/sign',
        name: 'sign',
        component: resolve => require(['~wrt/views/sign/index'], resolve)
    }, {
        path: '/multiChat',
        name: 'multiChat',
        component: resolve => require(['~wrt/views/multiChat/index'], resolve)
    }].concat([{
        path: "**",
        redirect:"/"
    }]),
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})
