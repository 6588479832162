<template>
  <div id="ailaWRTApp">
    <component :is="layout" >
      <router-view />
    </component>
  </div>
</template>
<script>
import defaultlayout from "./layouts/default.vue";

export default {
  components: {
    defaultlayout,
  },
  data() {
    return {
      default_layout: "default", //设置layout
    };
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || this.default_layout) + "layout";
    },
  },
};
</script>
<style lang="less">
#ailaWRTApp{
  height: 100%;
}
</style>
