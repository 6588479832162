
const i18nConfig = {
    schoolRecruitment: {
        locale: 'zh',
        useI18n: true,
        i18nKey: 'scrI18n',
        i18nBelong: '3,999',
        i18nEnCatalog: 'src/projects/schoolRecruitment/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/schoolRecruitment/locale/zh/i18nWord.js'
    },
    ymSaleTool: {
        locale: 'zh',
        useI18n: true,
        i18nKey: 'ystI18n',
        i18nBelong: '4,999',
        i18nEnCatalog: 'src/projects/schoolRecruitment/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/schoolRecruitment/locale/zh/i18nWord.js'
    },
    siffa: {
        useI18n: true,
        i18nKey: 'sifI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/siffa/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/siffa/locale/zh/i18nWord.js'
    },
    expoMeeting: {
        useI18n: true,
        i18nKey: 'expI18n',
        i18nBelong: '6,999',
        i18nEnCatalog: 'src/projects/expoMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/expoMeeting/locale/zh/i18nWord.js'
    },
    fudaMeeting: {
        useI18n: true,
        i18nKey: 'fdaI18n',
        i18nBelong: '6,999',
        i18nEnCatalog: 'src/projects/fudaMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/fudaMeeting/locale/zh/i18nWord.js'
    },
    ailaWRT: {
        useI18n: true,
        i18nKey: 'wrtI18n',
        i18nBelong: '999',
        i18nEnCatalog: 'src/projects/ailaWRT/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/ailaWRT/locale/zh/i18nWord.js'
    },
    tradeFederation:{
        locale: 'zh',
        useI18n: true,
        i18nKey: 'trfI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/tradeFederation/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/tradeFederation/locale/zh/i18nWord.js'
    },
    lySociety:{
        useI18n: true,
        i18nKey: 'lysI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/lySociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/lySociety/locale/zh/i18nWord.js'
    },
    scbSociety:{
        useI18n: true,
        i18nKey: 'scbI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/scbSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/scbSociety/locale/zh/i18nWord.js'
    },
    mpaSociety:{
        useI18n: true,
        i18nKey: 'mpaI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/mpaSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/mpaSociety/locale/zh/i18nWord.js'
    },
    overseasMeeting:{
        useI18n: true,
        i18nKey: 'osmI18n',
        i18nBelong: '999',
        i18nEnCatalog: 'src/projects/overseasMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/overseasMeeting/locale/zh/i18nWord.js'
    },
    ymPcClient:{
        useI18n: true,
        i18nKey: 'ympI18n',
        i18nBelong: '999',
        i18nEnCatalog: 'src/projects/ymPcClient/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/ymPcClient/locale/zh/i18nWord.js'
    },
    qlcSociety:{
        useI18n: true,
        i18nKey: 'qlcI18n',
        i18nBelong: '999',
        i18nEnCatalog: 'src/projects/qlcSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/qlcSociety/locale/zh/i18nWord.js'
    },
    airExpoMeeting: {
        useI18n: true,
        i18nKey: 'axpI18n',
        i18nBelong: '6,999',
        i18nEnCatalog: 'src/projects/airExpoMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/airExpoMeeting/locale/zh/i18nWord.js'
    },
    gbkSociety:{
        useI18n: true,
        i18nKey: 'gbkI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/gbkSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/gbkSociety/locale/zh/i18nWord.js'
    },
    hebeiSociety:{
        useI18n: true,
        i18nKey: 'hbsI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/hebeiSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/hebeiSociety/locale/zh/i18nWord.js'
    },
    xiffaSociety:{
        useI18n: true,
        i18nKey: 'xifI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/xiffaSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/xiffaSociety/locale/zh/i18nWord.js'
    },
    dlSociety:{
        useI18n: true,
        i18nKey: 'dlsI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/dlSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/dlSociety/locale/zh/i18nWord.js'
    },
    tiffaSociety:{
        useI18n: true,
        i18nKey: 'tifI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/tiffaSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/tiffaSociety/locale/zh/i18nWord.js'
    },
    tcbaSociety:{
        useI18n: true,
        i18nKey: 'tifI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/tcbaSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/tcbaSociety/locale/zh/i18nWord.js'
    },
    hphSociety:{
        useI18n: true,
        i18nKey: 'hphI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/hphSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/hphSociety/locale/zh/i18nWord.js'
    },
    henanSociety:{
        useI18n: true,
        i18nKey: 'hnsI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/henanSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/henanSociety/locale/zh/i18nWord.js'
    },
    dgaJob:{
        useI18n: true,
        i18nKey: 'dgbI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/dgaJob/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/dgaJob/locale/zh/i18nWord.js'
    },
    jzSociety:{
        useI18n: true,
        i18nKey: 'jzsI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/jzSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/jzSociety/locale/zh/i18nWord.js'
    },
    nbkjMeeting:{
        useI18n: true,
        i18nKey: 'nbkI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/nbkjMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/nbkjMeeting/locale/zh/i18nWord.js'
    },
    bfMeeting:{
        useI18n: true,
        i18nKey: 'bfmI18n',
        i18nBelong: '6,999',
        i18nEnCatalog: 'src/projects/bfMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/bfMeeting/locale/zh/i18nWord.js'
    },
    hymSociety: {
        useI18n: true,
        i18nKey: 'hymI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/hymSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/hymSociety/locale/zh/i18nWord.js'
    },
};
module.exports = i18nConfig;
