import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
Vue.config.productionTip = false
import * as filters from './plugins/filters'
import '~/basePlugins'
import store from './store/index'
import { getI18n } from '~/basePlugins/i18n'
import './assets/style/base.less'
import './assets/style/elementStyle/index.css'
import mixin from './mixin'

Vue.mixin(mixin);
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
});
(async () => {
    new Vue({
        render: h => h(App),
        router,
        store,
        i18n: await getI18n()
    }).$mount('#app')
})()

